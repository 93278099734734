import React, { useEffect, useState } from "react";
import { Controller } from "@/shared/ui";
import {
  emailValidationRule,
  inputValidationRule,
  phoneValidationRule,
} from "@/shared/helpers/const";

import { IInput, Input } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import {
  ContactEntity,
  UpdateLeadContactInput,
  useUpdateLeadContactMutation,
  useSetLeadStatusMutation,
  useGetLeadQuery,
} from "@/shared/lib/graphql";
import { Title } from "@yamaha-admin-sb/title";
import { DataFilled } from "./DataFilled";
import { StyledFormItem, StyledGridContainer } from "@/entities/leads";
import { IHandleSuccess } from "../../model/interfaces";

const PREFACTORY_STATUS = "prefatory";

export const ClientData: React.FC<{
  clientContact?: ContactEntity;
  leadId: number;
  nextStatus?: any;
  isCompleted: boolean;
  currentStatus: string;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}> = ({
  clientContact,
  leadId,
  handleSuccess,
  handleError,
  isCompleted,
  nextStatus,
  currentStatus,
}) => {
    const { mutate: updateLeadContact, isLoading: isDataUpdating } = useUpdateLeadContactMutation();
    const { refetch: updateLead } = useGetLeadQuery({ id: +leadId });
    const { mutate: setLeadStatus } = useSetLeadStatusMutation();
    const [form, setForm] = useState<UpdateLeadContactInput>({
      name: clientContact?.name || "",
      surname: clientContact?.surname || "",
      phone: clientContact?.phone || "",
      email: clientContact?.email || "",
    });
    useEffect(() => {
      setForm({
        name: clientContact?.name || "",
        surname: clientContact?.surname || "",
        phone: clientContact?.phone || "",
        email: clientContact?.email || "",
      });
      clientContact &&
        Object.keys(clientContact).forEach((item) =>
          // @ts-ignore
          setValue(item, clientContact[item])
        );
    }, [clientContact]);

    // useEffect(() => {
    //   // @ts-ignore
    //   Object.keys(form).forEach((item) => setValue(item, form[item]));
    // }, [clientContact]);

    const {
      formState: { errors },
      handleSubmit,
      control,
      trigger,
      setValue,
    } = useForm({ mode: "onBlur", defaultValues: { ...form } });

    const handleChange = (key: keyof UpdateLeadContactInput, value: string) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    };

    const submitCallback = () => {
      if (nextStatus?.slug === PREFACTORY_STATUS && currentStatus !== PREFACTORY_STATUS) {
        setLeadStatus(
          {
            leadId: +leadId,
            input: {
              statusId: nextStatus?.id,
            },
          },
          {
            onSuccess: () => {
              updateClientData();
            },
            onError: (error) => {
              handleError(error as ErrorEvent);
            },
          }
        );
        return;
      }

      updateClientData();
    };

    const updateClientData = () => {
      updateLeadContact(
        {
          leadId,
          input: {
            ...form,
            email: form.email ? form.email : null,
          },
        },
        {
          onSuccess: () => {
            updateLead();

            handleSuccess({
              text: "Данные клиента успешно сохранены",
              blockSlug: "client",
            });
          },
          onError: (error) => handleError(error as ErrorEvent),
        }
      );
    };

    return (
      <StyledFormItem>
        <div className="space-24">
          <Title level={4} weight={500} color="gray-700">
            Данные клиента
          </Title>
        </div>
        {!isCompleted ? (
          <>
            <StyledGridContainer className="space-20">
              <div>
                <Controller<IInput>
                  name="name"
                  label="Имя клиента *"
                  placeholder="Введите имя клиента"
                  control={control}
                  error={errors?.name?.message}
                  value={form.name}
                  isBordered={true}
                  rules={inputValidationRule}
                  onChange={(value) => handleChange("name", value)}
                  component={(inputProps: IInput) => <Input {...inputProps} />}
                />
              </div>
              <div>
                <Input
                  name="surname"
                  label="Фамилия клиента"
                  placeholder="Введите фамилию клиента"
                  value={form.surname || ""}
                  isBordered={true}
                  onChange={(value) => handleChange("surname", value)}
                />
              </div>
              <div>
                <Controller<IInput>
                  name="phone"
                  mask="+7 (999) 999-99-99"
                  label="Телефон клиента *"
                  placeholder="Введите телефон клиента"
                  control={control}
                  error={errors?.phone?.message}
                  value={form.phone}
                  isBordered={true}
                  rules={phoneValidationRule}
                  onChange={(value) => handleChange("phone", value)}
                  component={(inputProps: IInput) => <Input {...inputProps} />}
                />
              </div>
              <div>
                <Controller<IInput>
                  name="email"
                  label="E-mail клиента *"
                  placeholder="Введите e-mail клиента"
                  control={control}
                  error={errors?.email?.message}
                  value={form.email || ""}
                  isBordered={true}
                  rules={emailValidationRule}
                  onChange={(value) => handleChange("email", value)}
                  component={(inputProps: IInput) => <Input {...inputProps} />}
                />
              </div>
            </StyledGridContainer>
            <Button
              isLoading={isDataUpdating}
              onClick={() => {
                trigger();
                handleSubmit(submitCallback)();
              }}
            >
              Сохранить
            </Button>
          </>
        ) : (
          <DataFilled text="Данные клиента успешно сохранены" />
        )}
      </StyledFormItem>
    );
  };
