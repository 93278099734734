export const leadsTableHeaders = [
  {
    Header: "Номер заявки",
    accessor: "num",
    isSortable: true,
  },
  {
    Header: "Тип заявки",
    accessor: "type",
  },
  {
    Header: "Имя и номер клиента",
    accessor: "date",
    isSortable: true,
  },
  {
    Header: `Марка\\модель`,
    accessor: "client",
  },
  {
    Header: "Менеджер",
    accessor: "manager",
  },
  {
    Header: "Специалист",
    accessor: "specialist",
  },
  {
    Header: "Комментарий",
    accessor: "comment",
  },
  {
    Header: "Статус",
    accessor: "status",
  },
];

export const LeadsDisableTemplate = {
  HEAD_OF_MN: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  HEAD_OF_SP: {
    managerAppointment: true,
    specialistAppointment: false,
    createLead: true,
  },
  MANAGER: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  SN_MANAGER: {
    managerAppointment: false,
    specialistAppointment: true,
    createLead: false,
  },
  SPECIALIST: {
    managerAppointment: true,
    specialistAppointment: false,
    createLead: true,
  },
};
