import React from "react";
import { ContactEntity } from "@/shared/lib/graphql";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import {
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
  WrappedLeadTitle,
  WrappedBlock,
} from "../leads.styled";

interface ICommunication {
  contact?: ContactEntity | null;
  onEditClick: () => void;
  onDelete?: () => void;
}

export const Communication: React.FC<ICommunication> = (props) => {
  const { contact, onEditClick } = props;
  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Коммуникация с клиентом
        </Title>
        <IconButton icon="edit" variant="tertiary" color="gray" onClick={onEditClick} />
      </WrappedLeadTitle>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Дата следующего контакта
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          {contact?.recontact ? (
            <Paragraph size={12} color="gray-700" weight={400}>
              {new Date(contact.recontact).toLocaleString("ru-RU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })}
            </Paragraph>
          ) : (
            <Paragraph size={12} color="gray-500" weight={400}>
              Не указана
            </Paragraph>
          )}
        </StyledValueItem>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Комментарий
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          {contact?.comment ? (
            <Paragraph size={12} color="gray-700" weight={400}>
              Указан
            </Paragraph>
          ) : (
            <Paragraph size={12} color="gray-500" weight={400}>
              Не указан
            </Paragraph>
          )}
        </StyledValueItem>
      </StyledInfoItem>
      {contact?.clientAnswered !== null ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Статус
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={400}>
              {contact?.clientAnswered ? "Дозвонился" : "Не дозвонился"}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
    </WrappedBlock>
  );
};
