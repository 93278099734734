import { ModalContext } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { Input, IInput } from "@yamaha-admin-sb/input";
import { ModalBody, ModalFooter, Modal, ModalTop, Controller } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import {
  emailValidationWithoutRequired,
  inputValidationRule,
  phoneValidationRule,
} from "@/shared/helpers/const";
import {
  ClientEntity,
  ContactEntity,
  ContactTypeEnum,
  UpdateLeadContactInput,
  useUpdateLeadContactMutation,
} from "@/shared/lib/graphql";
import { IHandleSuccess } from "../../model/interfaces";

export interface IModal {
  leadId: number;
  contact?: ContactEntity | null;
  client?: ClientEntity | null;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
}

export const ModalEditContact: React.FC<IModal> = (props): JSX.Element => {
  const { leadId, contact, client, handleSuccess, handleError } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const mainEmail = client?.contacts?.find(
    (contact) => contact.type === ContactTypeEnum.Email && contact.isMain
  );
  const mainPhone = client?.contacts?.find(
    (contact) => contact.type === ContactTypeEnum.Phone && contact.isMain
  );

  const [form, setForm] = useState<UpdateLeadContactInput>({
    name: client?.name || "",
    surname: client?.surname || "",
    email: mainEmail?.value || "",
    phone: mainPhone?.value || "",
  });

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  const { mutate: updateContact, isLoading } = useUpdateLeadContactMutation();

  const handleChange = (key: keyof UpdateLeadContactInput, value: string | number) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdate = () => {
    console.log("update");

    updateContact(
      {
        leadId,
        input: {
          ...form,
          email: form.email ? form.email : null,
        },
      },
      {
        onSuccess: () => {
          handleButtonUndoClick();
          handleSuccess({
            text: "Данные клиента изменены",
            disableCompleteStage: true,
          });
        },
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalEditContact: false,
    }));
  };

  useEffect(() => {
    contact && Object.keys(contact).forEach((item) => setValue(item, contact[item]));
  }, [contact]);

  return (
    <Modal name="modalEditContact">
      <ModalTop title="Редактировать данные клиента" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalBody>
          <div className="space-20">
            <Controller<IInput>
              name="name"
              label="Имя *"
              placeholder="Введите имя"
              control={control}
              error={errors?.name?.message}
              value={form.name}
              isBordered={true}
              rules={inputValidationRule}
              onChange={(value) => handleChange("name", value)}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              isBordered={true}
              name="surname"
              label="Фамилия"
              placeholder="Введите фамилию"
              onChange={(value) => handleChange("surname", value)}
              control={control}
              value={form.surname}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              mask="+7 (999) 999-99-99"
              name="phone"
              label="Телефон *"
              placeholder="Введите номер телефона"
              isBordered={true}
              onChange={(value) => handleChange("phone", value)}
              control={control}
              error={errors?.name?.message}
              value={form.phone}
              rules={phoneValidationRule}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
          <div className="space-20">
            <Controller<IInput>
              isBordered={true}
              name="email"
              label="E-mail"
              placeholder="Введите e-mail"
              control={control}
              value={form.email}
              error={errors?.email?.message}
              rules={emailValidationWithoutRequired}
              onChange={(value) => handleChange("email", value)}
              component={(inputProps: IInput) => <Input {...inputProps} />}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
