import { ModalContext } from "@/shared/lib/react";
import React, { useContext, useEffect, useState } from "react";
import { ModalTop, Modal, ModalBody, ModalFooter, Datepicker } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { useForm } from "react-hook-form";
import { Maybe, UpdateCarDeliveryInput, useUpdateCarDeliveryMutation } from "@/shared/lib/graphql";
import { getFormattedDate } from "@/shared/helpers/utils";
import { IHandleSuccess } from "../../model/interfaces";

export interface IModal {
  leadId: number;
  contact?: Maybe<UpdateCarDeliveryInput>;
  handleSuccess: (value: IHandleSuccess) => void;
  handleError: (value: ErrorEvent) => void;
  currentlService?: string;
}

const CAR_SELECTION_SLUG = "car-selection";

export const ModalAutoRecontactData: React.FC<IModal> = (props): JSX.Element => {
  const { leadId, contact, handleSuccess, handleError, currentlService } = props;
  const [_, setModalContext = () => ({})] = useContext(ModalContext);

  const [form, setForm] = useState<{
    carDeliveryDate: Date | string | undefined | null;
  }>({
    carDeliveryDate: contact?.carDeliveryDate || undefined,
  });

  const { handleSubmit, setValue } = useForm({
    mode: "onBlur",
  });

  const { mutate: updateContact, isLoading } = useUpdateCarDeliveryMutation();

  const handleChange = (key: keyof UpdateCarDeliveryInput, value: Date | null) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleUpdate = () => {
    updateContact(
      {
        leadId,
        input: {
          carDeliveryDate: form.carDeliveryDate
            ? getFormattedDate(form.carDeliveryDate as Date)
            : null,
        },
      },
      {
        onSuccess: () => {
          handleButtonUndoClick();
          handleSuccess({
            text: "Дата следующего контакта изменена",
            disableCompleteStage: true,
          });
        },
        onError: (error) => handleError(error as ErrorEvent),
      }
    );
  };

  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalAutoRecontactData: false,
    }));
  };

  useEffect(() => {
    contact && Object.keys(contact).forEach((item) => setValue(item, contact[item]));
  }, [contact]);

  const labelDatepickerText =
    currentlService === CAR_SELECTION_SLUG ? "Дата выдачи автомобиля" : "Дата следующего контакта";

  return (
    <Modal name="modalAutoRecontactData">
      <ModalTop title="Дата выдачи автомобиля" onClose={handleButtonUndoClick} />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <ModalBody disableOverflow={true}>
          <div className="space-20">
            <Datepicker
              minDate={new Date()}
              value={(form.carDeliveryDate as string) || ""}
              label={labelDatepickerText}
              placeholder="Выберите дату"
              isBordered={true}
              isBlock={true}
              onInputOriginal={(date) => {
                handleChange("carDeliveryDate", date);
              }}
            />
          </div>
        </ModalBody>

        <ModalFooter>
          <Button variant="secondary" color="gray" onClick={handleButtonUndoClick}>
            Отменить
          </Button>
          <Button isLoading={isLoading} type="submit">
            Сохранить
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
